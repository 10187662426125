import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import { doc, setDoc, addDoc, collection } from "firebase/firestore"; 
import { db } from './init';
function App() {

  const [email, setEmail] = useState('');

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }
  const [email_submitted, set_email_submitted] = useState(false);
  const addEmail = async () => {
    await addDoc(collection(db, "email"), {
      email: email
    });
    set_email_submitted(true);
  }

 
  return (
    <div className="App">
        <div className='section1' >
        <div className='section1_half' id="boxes">
            <div className='section1_half_box' style={{ animation: 'fadeInAnimation 1.5s forwards 8s', opacity: 0 }}>
              <div className='text_box'><p className='text_box_text'>Lynxvise ensures all video discussing both you and your competitors are analyzed
              </p></div>
            </div>
            <div className='section1_half_box' style={{ animation: 'fadeInAnimation 1.5s forwards 0s', opacity: 0 }}>
            <div className='text_box1' ><p className='text_box_text' style={{color: 'black'}}>Viewers retain 95% of a message when they watch it in a video, 
            compared to 10% when reading it in the text.</p></div>
            </div>
            <div className='section1_half_box' style={{ animation: 'fadeInAnimation 1.5s forwards 12s', opacity: 0 }}>
            <div className='text_box' style={{borderRadius: '26px 0px 26px 26px', left: '35%'}}><p className='text_box_text'>Current social listening platforms analyze text not video</p></div>
            </div>
            <div className='section1_half_box' style={{ animation: 'fadeInAnimation 1.5s forwards 4s', opacity: 0 }}>
            <div className='text_box1'  style={{borderRadius: '26px 0px 26px 26px', left: '45%'}}><p className='text_box_text' style={{color: 'black'}}>90% of consumers watch videos in their free time</p></div>
            </div>
          </div>
          <div className='section1_half' id="boxes">
            <div className='section1_half_box' style={{ animation: 'fadeInAnimation 1.5s forwards 2s', opacity: 0 }}>
              <div className='text_box1' style={{borderRadius: '26px 26px 26px 0px', left: '50%'}}><p className='text_box_text' style={{color: 'black'}}>Over 70% of Gen Z watch online videos over three hours daily.</p></div>
            </div>
            <div className='section1_half_box' style={{ animation: 'fadeInAnimation 1.5s forwards 14s', opacity: 0 }}>
            <div className='text_box' style={{borderRadius: '26px 26px 26px 0px', left: '60%'}}><p className='text_box_text' >In order to know what users are saying about your product and others 
            you must  monitor short form content</p></div>
            </div>
            <div className='section1_half_box' style={{ animation: 'fadeInAnimation 1.5s forwards 6s', opacity: 0 }}>
            <div className='text_box1' style={{borderRadius: '0px 26px 26px 26px', left: '70%'}}><p className='text_box_text' style={{color: 'black'}}>Video content will make up 82% of global internet traffic by 2025</p></div>
            </div>
            <div className='section1_half_box' style={{ animation: 'fadeInAnimation 1.5s forwards 10s', opacity: 0 }}>
            <div className='text_box'  style={{borderRadius: '0px 26px 26px 26px', left: '60%'}}><p className='text_box_text' >From Videos to Insights: Monitoring Conversations Worldwide</p></div>
            </div>
          </div>
          
      <img src={require('./lynxvise_logo.png')} className='logo'/>
      <a className='button' href='#join'>Join lynxvise</a>
      </div>
      <div className='section1' style={{marginTop: '200px'}}>
          <div className='section2_half'>
            <div className='section2_half_box'>
              <div className='text_box2'><p className='text_box_text1'>Our AI-powered platform offers companies a groundbreaking opportunity to see how users discuss your products in both short-form and long-term video content. By analyzing keywords and user sentiment, your businesses can gain a comprehensive understanding of how their products are perceived in the video space. This detailed analysis allows companies to capture genuine user thoughts and conversations, providing invaluable insights into customer opinions and preferences. For the first time, businesses can directly see and analyze user-generated content about their products, enabling them to refine their strategies and better meet customer needs.
              </p></div>
            </div>
           
            <div className='section1_half_box'>
           
            </div>
            <div className='section2_half_box'>
              <div className='text_box2' style={{borderRadius: '0px 26px 26px 26px'}}><p className='text_box_text1'>In addition to product-specific insights, our platform allows companies to monitor and analyze how users talk about competitors in video content. By leveraging AI to dissect and interpret user discussions, businesses can gain a strategic advantage by understanding the strengths and weaknesses of their competitors as perceived by the audience. This dual focus on both their own products and competitor products in the video content realm equips companies with the knowledge needed to fine-tune their marketing and product development strategies. Ultimately, this comprehensive view helps businesses stay ahead of the curve in the competitive landscape.
              </p></div>
              </div>
          </div>
         
          
      <div className='logo'>
        <p className='logo_text'>Lynxvise's capabilities</p>
      </div>
      
      </div>
      <div className='section1_1' style={{marginTop: '200px'}} id="comp">
       {/* <p style={{color: 'white', fontSize: '50px'}}>Platform overview</p> */ }
        <div className='section1_1_1'>
         <div className='section1-text-holder'>
          <p className='section1_text'>View both the raw discussion and analytics through your lynxvise dashboard</p>
         </div>
         <div className='section1-image-holder'>
          <img src={require('./screen1.png')} className='section1_image'/>
         </div>
         </div>
         <div className='section1_1_1'>
         <div className='section1-image-holder'>
         <img src={require('./screen2.png')} className='section1_image'/>
         </div>
         <div className='section1-text-holder'>
         <p className='section1_text' style={{textAlign: 'right'}}>View the entire conversation of your companies footprint in the video landscape</p>
         </div>
         </div>
         <div className='section1_1_1'>
         <div className='section1-text-holder'>
          <p className='section1_text'>Get hyper-specific information on every video monitored</p>
         </div>
         <div className='section1-image-holder'>
         <img src={require('./screen3.png')} className='section1_image'/>
         </div>
         </div>
        </div>
        <div className='section1_1' style={{marginTop: '200px'}} id="phone">
       {/* <p style={{color: 'white', fontSize: '50px'}}>Platform overview</p> */ }
        <div className='section1_1_1'>
         <div className='section1-text-holder'>
          <p className='section1_text'>View both the raw discussion and analytics through your lynxvise dashboard</p>
         </div>
         <div className='section1-image-holder'>
          <img src={require('./screen1.png')} className='section1_image'/>
         </div>
         </div>
         <div className='section1_1_1'>
         <div className='section1-text-holder'>
         <p className='section1_text'>View the entire conversation of your companies footprint in the video landscape</p>
         </div>
         <div className='section1-image-holder'>
         <img src={require('./screen2.png')} className='section1_image'/>
         </div>
       
         </div>
         <div className='section1_1_1'>
         <div className='section1-text-holder'>
          <p className='section1_text'>Get hyper-specific information on every video monitored</p>
         </div>
         <div className='section1-image-holder'>
         <img src={require('./screen3.png')} className='section1_image'/>
         </div>
         </div>
        </div>
     <div className='grad_test' style={{marginTop: '200px'}}></div>
     <div className='section2'>
     <p className='logo1' style={{color: 'white', fontSize: '25px'}}>For just $99/mo, hear the entire conversation of you and your competitors services
     across all video content platforms</p>
     </div>
     <div className='grad_test1'></div>
     <div className='section3' id="join">
      <p className='find'>Listen like a lynx</p>
      {!email_submitted &&
      <div className='wrap'>
      <input type="text" placeholder='Email' className='input' onChange={handleEmail}></input>
      <p className='sub' onClick={addEmail}>Join today</p>
      </div>
}
{email_submitted &&
      <div className='wrap'>
      <p style={{color: 'white', fontSize: '25px'}}>You will be contacted by our team soon!</p>
      </div>
}
     <p className='lynxemail'>info@lynxvise.com</p>
      </div>
    </div>
  );
}

export default App;
